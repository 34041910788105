import React from "react";
import { Link } from "gatsby";
import * as s from "./PrimaryLinkButton.module.scss";
import arrowIcon from "../../images/cta/cta-arrow.svg";

const PrimaryLinkButton = ({ url, text, target }) => {
  return (
    <Link className={s.primaryButton} to={url} target={target}>
      {text}
      <span className={s.icon}>
        <img src={arrowIcon} alt="arrow-icon" />
      </span>
    </Link>
  );
};

export default PrimaryLinkButton;
