import * as React from "react";
import { Seo } from "../components/Helpers/Seo";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import PrimaryLinkButton from "../components/PrimaryLinkButton/PrimaryLinkButton";
import { Container, Row, Col } from "react-bootstrap";
import Helmet from "react-helmet";
import * as styles from "../styles/pages/404.module.scss";
import { graphql } from "gatsby";
import DOMPurify from "isomorphic-dompurify";

// call out seo properties here
export const Head = ({ data }) => <Seo
  title={data.wpPage.seo.title}
  description={data.wpPage.seo.metaDesc}
  keywords={data.wpPage.seo.metaKeywords}
/>;

const NotFoundPage = ({ data }) => {
  const mainSection = data.wpPage.acfNotFound.mainSectionCopy;

  return (
    <Layout>
      {/* set background color for <head> for short page */}
      <Helmet>
        <style>{"html {background-color:#ecebe7}"}</style>
      </Helmet>
      <Container fluid className="ellipse-background">
        <Header />
        <Container>
          <Row className="content-bottom-pad">
            <Col xs={12} lg={6} className="text-center">
              <img src={mainSection.sectionImage?.localFile.publicURL} alt={mainSection.sectionImage?.altText} className={styles.notFound__errorImage} />
            </Col>
            <Col xs={12} lg={6} className="ellipse-intro-text">
              <h1 dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(mainSection.heading)
              }} />
              <div style={{ marginBottom: 60 + "px" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(mainSection.bodyText)
                }} />
              <PrimaryLinkButton url={mainSection.actionLink.url} text={mainSection.actionLink.title} target={mainSection.actionLink.target} />
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
    query {
        wpPage(databaseId: { eq: 585 }) {
            seo{
                metaDesc
                title
                metaKeywords
            }
            acfNotFound {
                mainSectionCopy {
                    heading
                    bodyText
                    sectionImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    actionLink{
                      target
                      title
                      url
                    }
                }
            }
        }
    }
`;